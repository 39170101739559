import React, { useEffect } from "react";
import '../css/pages/home.css';
import HomepageBanner from '../img/homepageBg.png'
import AboutUs from '../img/digitalMarketing.gif';
import { scrollToDIV } from '../util';
export default function HomePage(){
    useEffect(() => {
        document.addEventListener('scroll',()=>{
            var elements = {'mainHomeHeading':'slideRightHeading',
                            'mainHomeSubHeading':'slideRightHeading',
                            'button':'slideDownButton',
                            'buttonWhite':'slideUpButton',
                            'homepageGif':'slideLeftImage'
                        }
            for(var index in elements){
                var element = document.getElementsByClassName(index)[0]
                if(element.style){
                    if(window.scrollY>window.outerHeight/3){
                        element.style.animation = 'hide' +elements[index]+ ' 0.5s forwards'
                    }
                    else{
                        element.style.animation = elements[index]+' 0.5s forwards'
                    }
                }
            }
        })
        
        return () => {
            
        }
    }, [])
    
    return <div id='home' className='wrapper'>
            <div style={{position:'relative',height:'100vh',width:'100vw'}}>
                <img src={HomepageBanner} className="backgroundImageHome" style={{position:'absolute',top:0}} alt='' height={'100%'} width={'100%'}/>
            </div>
            <div className="homeContainer">
                <div className="homeLeftWrapper">
                    <div className="mainHomeHeading">
                        Intelligence Driven Strategic <br/>
                        User Acquisition & Promotion
                    </div>
                    <div className="mainHomeSubHeading">
                        We maximize your user acquisition results through a complete<br/>
                        array of digital strategies covering the whole customer journey
                    </div>

                    {/* Button */}
                    <div className="getStartedContainer">
                        
                        <div id='button-5' className="buttonWhite">
                            <div id="translateWhite"></div>
                            <div style={{zIndex:'5'}}>Get Started</div>
                        </div>

                        <div id='button-5' className="button" onClick={()=>{scrollToDIV('contactusForm')}}>
                            <div id="translate"></div>
                            <div style={{zIndex:'5'}}>Contact Us</div>
                        </div>
                        {/* <a href="/freetrial" target='_blank' rel='noreferrer' style={{textDecoration:'none'}}> */}
                        {/* </a> */}
                    </div>
                    
                </div>
                <div className="homeRightWrapper">
                    <img src={AboutUs} alt="" className="homepageGif" width={'100vw'}/>
                </div>
            </div>
        </div>
};
  