import React from 'react'
import { scrollToDIV } from '../util'
import LinkedInLogo from '../img/linkedin.png';
import '../css/components/footer.css';
import Logobw from '../img/logobw.png'
const Footer = () => {
    return (
        <div className='footerContainer' style={{background:'orange',display:"flex",justifyContent:'space-around'}}>
            <div className='footerWrapper footerOne'>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'default'}} onClick={()=>{scrollToDIV('about')}}>
                    <img src={Logobw} alt='' width={'200px'}/>
                </div>
                <div style={{marginTop:'20px',marginBottom:'5px',fontSize:'17px',fontWeight:400,cursor:'default'}}>
                    Mobiaviator is the perfect match between Advanced technology and a  Team of experienced Professionals specialized on Digital Marketing ready to work with to increase clients ROI and achieve real results.
                </div>
            </div>
            <div className='footerWrapper footerTwo'>
                <div style={{marginBottom:'10px',fontSize:'24px',fontWeight:600}}>Our Services</div>
                <div style={{marginTop:'20px',marginBottom:'5px',fontSize:'16px',fontWeight:400,cursor:'default'}}>
                    <ul>
                        <li style={{marginBottom:'5px'}}>Digital Advertising</li>
                        <li style={{marginBottom:'5px'}}>Influencers Marketing</li>
                        <li style={{marginBottom:'5px'}}>User Acquisition</li>
                        <li style={{marginBottom:'5px'}}>Mobile Content</li>
                        <li style={{marginBottom:'5px'}}>Media Buying</li>
                    </ul>
                </div>
            </div>            
            <div className='footerWrapper footerThree'>
            <div style={{marginBottom:'10px',fontSize:'24px',fontWeight:600}}>Contact Us</div>
            <div style={{marginTop:'20px',marginBottom:'5px',fontSize:'16px',fontWeight:400,cursor:'default'}}>
                New Delhi, India
            </div>
                <div style={{marginTop:'20px',marginBottom:'5px',fontSize:'16px',fontWeight:400,cursor:'default'}}>
                    partner@mobiaviator.com
                </div>
                <div style={{marginTop:'20px',marginBottom:'5px',fontSize:'16px',fontWeight:400,cursor:'default'}}>
                    <a href='https://www.linkedin.com/company/mobiaviator-media/' target='_blank' rel='noreferrer'><img src={LinkedInLogo} alt=''></img></a>
                </div>
            </div>
        </div>
    )
}

export default Footer