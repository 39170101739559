import React, { useCallback, useEffect, useState } from 'react';
import '../css/pages/about.css';
import fraudPng from '../img/about/fraud_control.png';
import dataDrivenPng from '../img/about/data_driven.png';
import kpiPng from '../img/about/kpi.png';
import CountUp from 'react-countup';


export function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}


const AboutPage = () => {
    const refreshComponent = useForceUpdate()
    const [showCountUp, setShowCountUp] = useState({monthlyInstalls:0,monthlyCampaigns:0,partners:0,countries:0})
    useEffect(() => {
        window.addEventListener('scroll',()=>{
            if(document.getElementById('aboutContainer')){
                if(-(document.getElementById('aboutContainer').getBoundingClientRect().top+100)>window.innerHeight/2){
                    document.getElementById('aboutContainer').style.animation = 'hideContainerSlideUp 0.5s forwards'
                }
                else{
                    document.getElementById('aboutContainer').style.animation = 'containerSlideDown 0.4s forwards'
                    if(!showCountUp.monthlyInstalls){
                        setShowCountUp({monthlyInstalls:1500000,monthlyCampaigns:10000,partners:100,countries:50});
                        refreshComponent(1)
                    }
                    var elements = {'aboutslideUp':'slideUp','aboutslideDown':'slideDown','aboutslideRight':'slideRight','aboutRightContainer':'slideUp'}
                    for(var index in elements){
                        var element = document.getElementById(index)
                        if(element.style){
                            if(window.scrollY+window.innerHeight>element.getBoundingClientRect().top+100){
                                element.style.animation = elements[index]+' 0.4s forwards'
                            }
                            else{
                                element.style.animation = ''
                            }
                        }
                    }        
                }
            }
            
        })
    }, [showCountUp,refreshComponent])
    

    const millionFunction = useCallback((value) => {
        if(value>0){
            var millionValue = value
            if(value > 1000000){
                millionValue = (millionValue/1000000).toFixed(2) + "M"
            }
            return millionValue + "+"
        }
        else{
            return 0;
        }
      },
      [],
    )
    return (
        <div id='aboutContainer' className='aboutContainer'>
            <div className='aboutLeftContainer'>
                <table >
                    <tbody>
                            <tr>
                                <td rowSpan={2}>
                                        <div id='aboutslideRight' className='aboutLeftWrapper'>
                                            <img src={fraudPng} alt='' width='40%'/>
                                            <div className='aboutLeftHeading'>Fraud Control</div>
                                            <div className='aboutLeftText'>High Quality, Consistent, Scalable Traffic</div>
                                        </div>
                                </td>
                                <td>
                                    <div id='aboutslideDown' className='aboutLeftWrapper'>
                                        <img src={dataDrivenPng} alt='' width='40%'/>
                                        <div className='aboutLeftHeading'>Data Driven</div>
                                        <div className='aboutLeftText'>Customer preferences based AI driven strategies.</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div id='aboutslideUp' className='aboutLeftWrapper' style={{marginTop:'25%'}}>
                                        <img src={kpiPng} alt='' width='40%'/>
                                        <div className='aboutLeftHeading'>Meet Your KPI</div>
                                        <div className='aboutLeftText'>Unique Optimization Strategies to achieve hard KPIs.</div>
                                    </div>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
            <div id='aboutRightContainer' className='aboutRightContainer'>
                <div className='aboutRightContainerHeading'>
                    We are Trusted Reliable & <br/>
                    Efficient Traffic Partner
                </div>
                <div className='aboutRightContainerDetails'>
                    Mobiaviator is a full-service mobile marketing platform expertised in attracting loyal and most Rewarding customers. We help advertisers in reaching customers with personalized content, by unveiling preferences and habits. You only pay for real results and new customers.
                </div>
                <div className='aboutRightContainerCountUpWrapper' >
                    <div className='aboutLeftCountUp'>
                        {showCountUp && <CountUp
                            start={0}
                            end={showCountUp.monthlyCampaigns}
                            duration={2.75}
                            suffix="+"
                            >
                            {({ countUpRef }) => (
                                <div className='aboutCountUpDetail'>
                                    <span ref={countUpRef} />
                                    <div style={{fontFamily:'poppins',marginTop:'20px',fontSize:'18px',textAlign:'center',color:'rgb(90,90,90)'}}>
                                        Monthly <br/>
                                        Campaigns
                                    </div>
                                </div>
                            )}
                        </CountUp>
                        }
                    </div>
                    <div className='aboutRightCountUp'>
                        <CountUp
                            start={0}
                            end={showCountUp.monthlyInstalls}
                            duration={2.75}
                            formattingFn = {millionFunction}
                            >
                            {({ countUpRef }) => (
                                <div className='aboutCountUpDetail'>
                                    <span ref={countUpRef} />
                                    <div style={{fontFamily:'poppins',marginTop:'20px',fontSize:'18px',textAlign:'center',color:'rgb(90,90,90)'}}>
                                        Monthly <br/>
                                        Installs
                                    </div>
                                </div>
                            )}
                        </CountUp>
                        
                    </div>
                </div>

                <div className='aboutRightContainerCountUpWrapper' style={{marginTop:'0px'}}>
                    <div className='aboutLeftCountUp'>
                        <CountUp
                            start={0}
                            end={showCountUp.partners}
                            duration={2.75}
                            suffix="+"
                            >
                            {({ countUpRef }) => (
                                <div className='aboutCountUpDetail'>
                                    <span ref={countUpRef} />
                                    <div style={{fontFamily:'poppins',marginTop:'20px',fontSize:'18px',textAlign:'center',color:'rgb(90,90,90)'}}>
                                        Partners <br/>
                                    </div>
                                </div>
                            )}
                        </CountUp>
                        
                    </div>
                    <div className='aboutRightCountUp'>
                        <CountUp
                            start={0}
                            end={showCountUp.countries}
                            duration={2.75}
                            formattingFn = {millionFunction}
                            >
                            {({ countUpRef }) => (
                                <div className='aboutCountUpDetail'>
                                    <span ref={countUpRef} />
                                    <div style={{fontFamily:'poppins',marginTop:'20px',fontSize:'18px',textAlign:'center',color:'rgb(90,90,90)'}}>
                                        Countries <br/>
                                    </div>
                                </div>
                            )}
                        </CountUp>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;