import React,{useState,useCallback,useEffect,useMemo} from 'react';
import '../css/components/topbar.css';
import { getCoords, scrollToDIV } from '../util';
import Logo from '../img/logo.png';
const Topbar = () => {
    const [lastScrollPosition, setLastScrollPosition] = useState(window.scrollY);
    // const [topbarTopPosition, setTopbarTopPosition] = useState(0);
    // const [underlineLeft, setUnderlineLeft] = useState();
    const [activeTab, setActiveTab] = useState(false);
    const [responsiveMenu, setResponsiveMenu] = useState(false);
    
    const topbarTabs = useMemo(() => [
        {id:0,label:'Home',linkId:'home',hide:true},
        {id:1,label:'About Us',linkId:'about'},
        {id:3,label:'Company',linkId:'company',dropdown:[{label:'Why Us?',value:'whyus'},{label:'What we do?',value:'whatwedo'},{label:'Our Partners',value:'our_partners'},{label:'Our Team',value:'our_team'}]},
        {id:5,label:'Contact Us',linkId:'contactus'},
    ], []);
    const handleNavigation = useCallback(
    e => {
        const window = e.currentTarget;
        setLastScrollPosition(window.scrollY);
        topbarTabs.forEach(element => {
            // var docElementTab = document.getElementById(element.id)
            var docElementPage = document.getElementById(element.linkId)
            // var body = document.body,
            // html = document.documentElement;

            // var documentHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                                // html.clientHeight, html.scrollHeight, html.offsetHeight );
            if(docElementPage){
                // var coordsTab = getCoords(docElementTab);
                var coordsPage = getCoords(docElementPage);
                if(window.scrollY+30>=coordsPage.top){
                    setActiveTab(element.id);
                }
                
                // if(window.scrollY>=coordsPage.top-200 && window.scrollY<(docElementPage.getBoundingClientRect().bottom+window.scrollY-200)){
                //     setUnderlineLeft(coordsTab.left+(window.scrollY/docElementTab.getBoundingClientRect().width))
                // }
            }
        })
    }, [topbarTabs]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const [hoveredItem, setHoveredItem] = useState(false)
    return (
        <div className='topbarContainer' style={{background:(lastScrollPosition>50 ? 'white' : 'transparent'),boxShadow:(lastScrollPosition>50 ? '0 0 20px 1px rgb(0, 0, 0, 0.1)' : '0 0 10px 1px transparent'),backdropFilter:(window.scrollY>40 ? 'blur(2px)' : 'none'),color:(window.scrollY>40 ? 'black' : 'white')}}>
            <div style={{width:'30%',marginLeft:'5%'}}><img onClick={(e)=>{scrollToDIV('home')}} src={Logo} className='companyLogoTopbar' alt=''/></div>
            <div style={{display:'flex',width:'70%',alignItems:'center',justifyContent:'flex-end'}}>
                <div className='topbarNavigationMenu'>        
                    {topbarTabs.map((item,index)=>{
                        if(!item.hide){
                            if(item.dropdown){
                                return <div id={item.id} style={{position:'relative',display:'flex',alignItems:'flex-start',flexDirection:'column',justifyContent:'flex-start'}} className={activeTab===item.id ? 'topbarNavigationMenuTabs navItemActive' :'topbarNavigationMenuTabs' }
                                        key={index} 
                                        onClick={(e)=>{scrollToDIV(item.linkId)}}
                                        onMouseEnter={()=>{setHoveredItem(item.value);}}
                                        onMouseLeave={()=>{setHoveredItem(false)}}
                                    > 
                                    <div style={{display:'flex'}}>{item.label} <label style={{marginLeft:'5px',transform:'rotate(90deg) scale(0.8)'}}>{">"}</label></div>
                                    <div style={{background:(lastScrollPosition>50 ? 'white' : 'transparent')}} className={hoveredItem === item.value  ? 'hoveredItem' : 'hoveredItem hideHoveredItem'}>
                                        {item.dropdown.map((dropdownItem,dropdownIndex)=>{
                                            return <div key={dropdownIndex} className={hoveredItem === item.value  ? 'hoveredDataItem' : 'hoveredDataItem hideHoveredDataItem'} onClick={(e)=>{e.stopPropagation();scrollToDIV(dropdownItem.value)}}>
                                                {dropdownItem.label}
                                            </div>
                                        })}
                                    </div>
                                </div>
                            }
                            if(item.linkId==='new_feature'){
                                return <div id={item.id} className={'topbarNavigationMenuTabs'}
                                            key={index} 
                                            onClick={(e)=>{scrollToDIV(item.linkId)}}
                                        > 
                                        <div className='comingsoonItem'>
                                            <div>{item.label}  </div>
                                            {/* <div className='comingsoonItemLabel'>{('(Coming Soon)')} </div> */}
                                        </div>
                                </div>
                            }
                            return <div id={item.id} className={activeTab===item.id ? 'topbarNavigationMenuTabs navItemActive' :'topbarNavigationMenuTabs' }
                                        key={index} 
                                        onClick={(e)=>{scrollToDIV(item.linkId)}}
                                    > 
                                    {item.label} 
                                </div>
                        }
                        return null;
                    })}
                </div>
                <div className='threeDotMenuButton' onClick={()=>{setResponsiveMenu(!responsiveMenu)}}>
                    ...
                </div>
            </div>
            <div className={responsiveMenu ? 'topbarNavigationMobileMenu' : 'topbarNavigationMobileMenuDisplayNone'}>
                {topbarTabs.map((item,index)=>{
                        if(!item.hide){
                            
                            if(item.linkId==='new_feature'){
                                return <div id={item.id} className={'topbarNavigationMenuTabs'}
                                            key={index} 
                                            onClick={(e)=>{scrollToDIV(item.linkId)}}
                                        > 
                                        <div className='comingsoonItem'>
                                            <div>{item.label}  </div>
                                            {/* <div className='comingsoonItemLabel'>{('(Coming Soon)')} </div> */}
                                        </div>
                                </div>
                            }
                            return <div id={item.id} className={activeTab===item.id ? 'topbarNavigationMenuTabs navItemActive' :'topbarNavigationMenuTabs' }
                                        key={index} 
                                        onClick={(e)=>{scrollToDIV(item.linkId)}}
                                    > 
                                    {item.label} 
                                </div>
                        }
                        return null;
                    })}
            </div>
        </div>
    )
}

export default Topbar