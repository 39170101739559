import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../css/pages/company.css";

// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import Apptastic from '../img/company/Apptastic.png'
import Instal from '../img/company/instal.png'
import Collectcent from '../img/company/collectcent.png'
import ApeironAds from '../img/company/Apeironads.png'
import TestimonialsImage from '../img/testimonials.jpg'
export default function CompanyPartners() {
    var testimonials = [
        'C','V','M','C'
    ]
    const paginationFunction = {
        "clickable": true,
        "renderBullet": function (index, className) {
                return '<div class= '+className+'>' + (testimonials[index]) + '</div>';

              }
      }
  return (
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        // coverflowEffect={{
        //   rotate: 0,
        //   stretch: 100,
        //   depth: 200,
        //   modifier: 1,
        //   slideShadows: true,
        // }}
        autoplay={{ delay: 3000 }}
        navigation={{
            nextEl: '.swiper-button-next',
        }}
        pagination={paginationFunction}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="testimonialContainer">
                <div className="testimonialWrapper">
                    <div style={{textAlign:'center',marginTop:'0px'}}><img src={TestimonialsImage} alt='' width={'15%'}/></div>
                    <div style={{textAlign:'center',marginTop:'10px'}}><img style={{background:'black',padding:'10px'}} src={Apptastic} alt='' width={'40%'}/></div>
                    <div style={{fontSize:'18px',textAlign:'justify',padding:'30px',marginTop:'-0'}}>
                        <i>
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                            Mobiaviator has a very responsive team, with experience and commitment to help us meet our clients' KPIs. Excellent team work and planning that allows us to align our requirements with their capabilities and get the best possible results.
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                        </i>
                    </div>
                    <div style={{display:'flex',fontWeight:600,justifyContent:'center',fontSize:'28px',color:'rgb(50,50,50)',marginBottom:'40px'}}>
                        Carlos
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="testimonialContainer">
                <div className="testimonialWrapper">
                    <div style={{textAlign:'center',marginTop:'0px'}}><img src={TestimonialsImage} alt='' width={'15%'}/></div>
                    <div style={{textAlign:'center',marginTop:'10px'}}><img src={Instal} alt='' width={'30%'}/></div>
                    <div style={{fontSize:'18px',textAlign:'justify',padding:'30px',marginTop:'-0'}}>
                        <i>
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                            Great and trusted partners since many years and have to say they are always crucial part of our success. Always helpful in trying their best to match interests and onboard to scale our business together! We are glad to work together with them.
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                        </i>
                    </div>
                    <div style={{display:'flex',fontWeight:600,justifyContent:'center',fontSize:'28px',color:'rgb(50,50,50)',marginBottom:'40px'}}>
                        Viktoria
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="testimonialContainer">
                <div className="testimonialWrapper">
                    <div style={{textAlign:'center',marginTop:'0px'}}><img src={TestimonialsImage} alt='' width={'15%'}/></div>   
                    <div style={{textAlign:'center',marginTop:'20px'}}><img src={ApeironAds} alt='' width={'50%'}/></div>
                    <div style={{fontSize:'18px',textAlign:'justify',padding:'30px'}}>
                        <i>
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                            I would consider Mobiaviator as a balanced combination of good traffic and quick optimisation. Always friendly, responsive and take new challenges! The team always keep an eye on traffic quality, always ask for reports in order to be sure that they provide only the best value. ApeironAds is very happy to have such valuable partner on board!
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                        </i>
                    </div>
                    <div style={{display:'flex',fontWeight:600,justifyContent:'center',fontSize:'28px',color:'rgb(50,50,50)',marginBottom:'40px'}}>
                        Max
                    </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="testimonialContainer">
                <div className="testimonialWrapper">
                    <div style={{textAlign:'center',marginTop:'0px'}}><img src={TestimonialsImage} alt='' width={'15%'}/></div>
                    <div style={{textAlign:'center',marginTop:'20px'}}><img style={{padding:'10px'}} src={Collectcent} alt='' width={'50%'}/></div>
                    <div style={{fontSize:'18px',textAlign:'justify',padding:'30px'}}>
                        <i>
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                            Working with "Mobiaviator" for a good amount of time, Mobiaviator exudes a high level of energy at work and is always helpful in delivering value to the customer with scalable Business.
                            <label style={{color:'orange',fontSize:'22px'}}>"</label>
                        </i>
                    </div>
                    {/* <div style={{marginLeft:'30px',fontSize:'28px',color:'rgb(50,50,50)'}}>
                        - <img src={Collectcent} alt='' width={'35%'}/>
                    </div> */}
                    {/* <div style={{marginLeft:'50px',fontSize:'18px',color:'rgb(50,50,50)'}}>
                        Collectcent
                    </div> */}
                </div>
            </div>
        </SwiperSlide>
       
        
      </Swiper>
  );
}
