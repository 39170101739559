import React from 'react'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import '../css/pages/contact.css';

const ContactUs = () => {
    return (
        <div style={{paddingTop:'70px',display:"flex",flexDirection:'column',alignItems:'center'}}>
            <iframe src="https://maps.google.com/maps?q=new%20delhi%20india&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                    width={window.innerWidth} 
                    height={window.innerHeight/1.2} 
                    frameborder="0" 
                    style={{border:0}} 
                    allowfullscreen="" 
                    aria-hidden="false" 
                    title='mapsss'
                    tabindex="0">    
            </iframe>
            <div  id='contactusForm' style={{position:'absolute',marginTop:window.innerHeight/2}}></div>
            <div className='contactusFormWrapper' style={{textAlign:'center',background:'rgb(37,37,37)',marginTop:'-20vh',minHeight:'calc(100vh - 70px)',borderRadius:'30px',boxShadow:"0 0 10px 1px rgb(200,200,200,1)"}}>
                <div style={{marginTop:'30px',fontSize:'36px',fontWeight:'500',color:'white'}}>
                    Contact Us
                </div>
                <div style={{marginTop:'15px',fontSize:'18px',fontWeight:'500',color:'rgb(200,200,200)'}}>
                    Any questions or remarks? Just write us a message!
                </div>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'60vh'}}>
                    <div style={{display:'flex',width:'70%',marginTop:'20px'}}>
                        <TextField 
                            fullWidth
                            label="First Name" 
                            variant="standard" 
                            color='warning'
                            size='small'
                            sx={{ input: { color: "white" }, "label": {color: "rgb(180,180,180)"} }} 
                        />
                        <TextField 
                            fullWidth
                            label="Last Name" 
                            variant="standard" 
                            color='warning'
                            size='small'
                            sx={{ marginLeft:'20px',input: { color: "white" }, "label": {color: "rgb(180,180,180)"} }} 
                        />
                    </div>
                    <div style={{display:'flex',width:'70%',marginTop:'20px'}}>
                        <TextField 
                            fullWidth
                            label="Email" 
                            variant="standard" 
                            color='warning'
                            size='small'
                            sx={{ input: { color: "white" }, "label": {color: "rgb(180,180,180)"} }} 
                        />
                        <TextField 
                            fullWidth
                            label="Phone Number" 
                            variant="standard" 
                            color='warning'
                            size='small'
                            sx={{ marginLeft:'20px',input: { color: "white" }, "label": {color: "rgb(180,180,180)"} }} 
                        />
                    </div>
                    <div style={{display:'flex',width:'70%',marginTop:'20px'}}>
                        <TextField 
                            fullWidth
                            multiline
                            maxRows={8}
                            rows={5}
                            label="Message" 
                            variant="standard" 
                            color='warning'
                            size='small'
                            inputProps={{ style: { color: "white" } }}
                            sx={{ "label": {color: "rgb(180,180,180)"} }} 
                        />
                    </div>
                </div>
                <Button color={'warning'}>Submit</Button>
            </div>
        </div>
    )
}

export default ContactUs