import React, { useEffect } from 'react'
import '../css/pages/company.css';
import targetOrientedImage from '../img/company/targetOriented.png'
import performanceMarketingImage from '../img/company/performanceMarketing.png'
import antifraudImage from '../img/company/antifraud.png'
import rightGif from '../img/company/rightGIf.gif'

import affiliateTeamImage from '../img/company/affiliateTeam.png'
import publishersImage from '../img/company/publishers.png'
import whatWeDo1 from '../img/company/whatwedo1.png'
import whatWeDo2 from '../img/company/whatwedo2.png'
import whatWeDo3 from '../img/company/whatwedo3.png'
import advertiserImage from '../img/company/advertisers.png'

import Adjust from '../img/tracker/Adjust.png'
import Appsflyer from '../img/tracker/Appsflyer.png'
import Branch from '../img/tracker/Branch.png'
import Kochava from '../img/tracker/Kochava.png'
import Singular from '../img/tracker/Singular.png'
function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
const CompanyPage = () => {
    useEffect(() => {
        window.addEventListener('scroll',()=>{
            if(document.getElementById('whyus')){
                if(isInViewport(document.getElementById('whyus'))){
                    document.getElementById('whyus').style.animation = 'containerSlideDown 0.4s forwards'
                }
                else{
                    document.getElementById('whyus').style.animation = 'containerSlideDown 0.4s forwards'
                }
                var elements = {whyusHeadingSlideUp:'slideUp','whyusSlideRight':'slideRight','whyusSlideDown':'slideDown','whyusSlideLeft':'slideLeft','whyusSlideUp':'slideUp',whyusGifSlideLeft:'slideLeft'}
                for(var index in elements){
                    var element = document.getElementById(index)
                    if(element.style){
                        if(isInViewport(element)){
                            element.style.animation = elements[index]+' 0.4s forwards'
                        }
                        else{
                            if(window.innerWidth>600){
                                element.style.animation = elements[index]+'Hide 0.8s forwards'
                            }
                        }
                    }
                }     
            }
            if(document.getElementById('whatwedo')){
                if(isInViewport(document.getElementById('whatwedo'))){
                    document.getElementById('whatwedo').style.animation = 'containerSlideDown 0.4s forwards'
                }
                else{
                    document.getElementById('whatwedo').style.animation = 'containerSlideDown 0.4s forwards'
                }
                // elements = {whatwedoHeadingSlideUp:'slideUp',whatwedoTile1:'slideRight',whatwedoTile2:'slideDown',whatwedoTile3:'slideLeft'}
                // for(index in elements){
                //     element = document.getElementById(index)
                //     if(element.style){
                //         if(isInViewport(element)){
                //             element.style.animation = elements[index]+' 0.4s forwards'
                //         }
                //         else{
                //             element.style.animation = elements[index]+'Hide 0.8s forwards'
                //         }
                //     }
                // }     
            }
        })
    }, [])
    
    return (
        <div className='companyPageContainer' style={{overflowY:'hidden'}}>
            
            <div id='whyus' className='whyUsWrapper'>
                <div className='whyusleftContainer'>
                    <div id='whyusHeadingSlideUp' className='whyUsHeading' style={{opacity:0,transform:'translateY(16%)'}}>
                        We are recognized in the industry for...
                    </div>
                    <div className='whyusleftWrapper'>
                        <div className='whyusleftleftWrapper' id='whyusSlideRight' style={{opacity:0,transform:'translateX(-16%)',position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img className='whyusImageBg' src={targetOrientedImage} alt=''/>
                            <div className='whyUsPointData'>
                                Refined Target oriented campaigns with Top Notch worldwide traffic sources aiming to reach and engage the most fitting users for customers' business and turning each download in to a loyal user. We leverage the power of data and geo-location to engage every user in a personalised way.
                            </div>
                        </div>
                        <div id='whyusSlideDown' className='whyusrightrightWrapper' style={{opacity:0,transform:'translateY(16%)',position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img className='whyusImageBg' src={performanceMarketingImage} alt=''/>
                            <div className='whyUsPointData'>
                                We Believe in performance based marketing. We are recognized leading agency focused on driving new customers for our advertisers on a Cost-Per-install (CPI), Cost-Per-Lead (CPL), Cost-Per-Action (CPA), Cost-Per-Sale (CPS) and Cost-per-Registration (CPR) pricing models.
                            </div>
                        </div>
                    </div>
                    <div className='whyusleftWrapper'>
                        <div className='whyusleftleftWrapper' id='whyusSlideLeft' style={{opacity:0,transform:'translateX(-16%)',position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img className='whyusImageBg' src={antifraudImage} alt=''/>
                            <div className='whyUsPointData'>
                                Cutting-Edge Fraud Control Technology analyzes and blocks suspicious activity in real time. We are deeply committed to protect you from fradulant traffic and provide you detailed reports down to the product and publisher level.
                            </div>
                        </div>
                        <div id='whyusSlideUp' className='whyusrightrightWrapper rightbottomWrapperWhyUS' style={{opacity:0,transform:'translateY(16%)',position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img className='whyusImageBg' src={affiliateTeamImage} alt=''/>
                            <div className='whyUsPointData'>
                                Creative Affiliate Managers to plan, develop and advertise campaigns throughout multiple channels. Dedicated Account Managers to assist customers 24/7 over Skype and Email.
                            </div>
                        </div>
                    </div>
                </div>
                <div id='whyusGifSlideLeft' className='whyusImage' style={{width:'30%',marginLeft:'0%'}}>
                    <img className='whyusImageBg' src={rightGif} alt='' width={'120%'} />
                </div>
            </div>
            <div id='whatwedo' style={{minHeight:'80vh',paddingTop:'100px',width:'100%'}}>
                <div id='whatwedoHeadingSlideUp' className='whyUsHeading' style={{opacity:0,transform:'translateY(16%)',textAlign:'center'}}>
                    What We Do
                </div>
                <div className='whatwedoTileContainer'>
                    <div id='whatwedoTile1' className='whatwedoTiles'>
                        <img src={publishersImage} alt='' width={'30%'}/>
                        <div style={{fontSize:'28px',marginTop:'20px'}}>Publishers</div>
                        <div style={{padding:'20px',textAlign:'center',fontSize:'13px'}}>Thousands of active campaigns in 100+ countries with premium payouts. Intuitive Platform for real-time performance tracking and transparent reporting.</div>
                        <div style={{fontSize:'13px'}}>
                            <ul>
                                <li>Exclusive Offers</li>
                                <li>Highest Rates</li>
                                <li>Offer Testing</li>
                                <li>OnTime Payments</li>
                            </ul>
                        </div>
                    </div>
                    <div id='whatwedoTile2' className='whatwedoTiles'>
                        <div style={{textAlign:'center'}}>
                            <img src={whatWeDo1} alt='' width={'25%'}/>
                            <div>Campaign Optimization</div>
                        </div>
                        <div style={{textAlign:'center',marginTop:'20px'}}>
                            <img src={whatWeDo3} alt='' width={'25%'}/>
                            <div>Custom Payment Methods</div>
                        </div>
                        <div style={{textAlign:'center',marginTop:'20px'}}>
                            <img src={whatWeDo2} alt='' width={'25%'}/>
                            <div>Multi Format Ads</div>
                        </div>
                    </div>
                    <div id='whatwedoTile3' className='whatwedoTiles'>
                        <img src={advertiserImage} alt='' width={'30%'}/>
                        <div style={{fontSize:'28px',marginTop:'20px    '}}>Advertisers</div>
                        <div style={{padding:'20px',textAlign:'center',fontSize:'13px'}}>Improvised partnership with our clients to plan and execute superior marketing strategies. We deliver outstanding results across a wide range of User Acquisition channels.</div>
                        <div style={{fontSize:'13px'}}>
                            <ul>
                                <li>Pay Per Performance</li>
                                <li>Transparency</li>
                                <li>Targeted Traffic</li>
                                <li>Realtime Data & Tracking</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id='our_partners' style={{paddingTop:'100px',overflowY:'hidden'}}>
                <div id='whatwedoHeadingSlideUp' className='whyUsHeading' style={{opacity:0,transform:'translateY(16%)',textAlign:'center'}}>
                    Our Partners.
                </div>
                <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                    <div className='partnersImage'><img style={{marginTop:'10px'}} src={Adjust} alt='' width={'100%'}/></div>
                    <div className='partnersImage'><img src={Branch} alt='' width={'100%'}/></div>
                    <div className='partnersImage'><img style={{marginTop:'10px'}} src={Appsflyer} alt='' width={'100%'}/></div>
                    <div className='partnersImage'><img src={Kochava} alt='' width={'100%'}/></div>
                    <div className='partnersImage'><img src={Singular} alt='' width={'100%'}/></div>
                </div>
            </div>
        </div>
      )
}

export default CompanyPage