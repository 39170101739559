import './App.css';
import Topbar from './components/topbar';
import HomePage from './pages/home';
import AboutPage from './pages/about';

import Footer from './pages/footer';
import CompanyPage from './pages/company';
import CompanyPartners from './pages/company_partners';
import ContactUs from './pages/contactus';
import { useState } from 'react';
// import Footer from './pages/footer';

function App() {
    const [teamMateHovered, setTeamMateHovered] = useState(false)
    return (
        <div className="App" style={{width:'100vw',overflowX:'hidden'}}>
            <Topbar/>
            <HomePage/>
            
            <div id='information'>
                <div id='about'>
                    <AboutPage/>
                </div>
				<div id='company'>
                    <CompanyPage/>
                </div>
                <div>
                    <div className='testimonials'>
                        <div style={{width:'100%'}}>
                            <div id='whatwedoHeadingSlideUp' className='whyUsHeading' style={{opacity:0,transform:'translateY(16%)',textAlign:'center',marginBottom:'10px'}}>
                                <div style={{fontSize:'28px',marginBottom:'10px',color:'orange'}}>Testimonials</div>
                                <div style={{color:'rgb(37,37,37)'}}>What people think about us?</div>
                            </div> 
                            <div>
                                <CompanyPartners/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='our_team' style={{paddingTop:'100px',overflowY:'hidden'}}>
                    <div id='whatwedoHeadingSlideUp' className='whyUsHeading' style={{opacity:0,transform:'translateY(16%)',textAlign:'center'}}>
                        Our awe-inspiring Team.
                    </div>
                    <div className='teamMemberContainer' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <div className='teamMemberWrapper' onMouseEnter={()=>{setTeamMateHovered('J')}} onMouseLeave={()=>{setTeamMateHovered(false)}}>
                            J
                            <div className={teamMateHovered==='J' ? 'teammateHovered' : 'teammateHoveredHidden teammateHovered'}>
                                <div style={{fontWeight:'600'}}>Jitendra</div>
                                <div style={{fontWeight:'500',fontSize:'15px',textAlign:"center"}}>Head of Ad Ops and Client Partnership</div>
                            </div>
                        </div>
                        <div className='teamMemberWrapper' onMouseEnter={()=>{setTeamMateHovered('V')}} onMouseLeave={()=>{setTeamMateHovered(false)}}>
                            V
                            <div className={teamMateHovered==='V' ? 'teammateHovered' : 'teammateHoveredHidden teammateHovered'}>
                                <div style={{fontWeight:'600'}}>Vikas</div>
                                <div style={{fontWeight:'500',fontSize:'15px',textAlign:"center"}}>Business Development Manager</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='contactus' style={{marginBottom:'40px'}}>
                    <ContactUs/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
